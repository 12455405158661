<template>
  <nav class="navbar navbar-expand-lg navbar-dark primary">
    <div class="container-fluid" v-for="menu in mainMenu" :key="menu">
      <div class="responsivecheck">
        <div class="logo-wrapper">
          <a class="navbar-brand" href="../"> <ICON_WSLogo /></a
          ><span class="beta">BETA</span>
        </div>
        <div class="desktop">
          <div class="hiringtext d-flex">
            <a class="nav-link" href="../careers">{{ menu.careers_title }}</a>
          </div>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          id="burger"
          :class="{ active: isBurgerActive }"
          @click.prevent="toggle"
        >
          <button type="button" class="burger-button" title="Menu">
            <span class="burger-bar burger-bar--1"></span>
            <span class="burger-bar burger-bar--2"></span>
            <span class="burger-bar burger-bar--3"></span>
          </button>
          <!-- <span class="navbar-toggler-icon"></span> -->
        </button>
      </div>
      <div class="collapse navbar-collapse" id="main_nav">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item dropdown has-megamenu desktopmainmenu">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ menu.menu_1 }}</a
            >
            <div class="dropdown-menu megamenu p-0" role="menu">
              <div class="container-fluid">
                <div class="row">
                  <Resources
                    :group1="menu.option_1_group_1"
                    :group2="menu.option_1_group_2"
                  />
                  <ResourcesBlogs />
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown has-megamenu desktopmainmenu">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ menu.menu_2 }}</a
            >
            <div class="dropdown-menu megamenu p-0" role="menu">
              <div class="container-fluid">
                <div class="row">
                  <ShowCases
                    :group1="menu.option_2_group_1"
                    :group2="menu.option_2_group_2"
                  />
                  <ShowCasesBlogs />
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown has-megamenu mobilemainmenu">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ menu.menu_1 }}</a
            >
            <div class="dropdown-menu megamenu p-0" role="menu">
              <div class="container-fluid">
                <div class="row">
                  <ResourcesMobile
                    :group1="menu.option_1_group_1"
                    :group2="menu.option_1_group_2"
                  />
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown has-megamenu mobilemainmenu">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ menu.menu_2 }}</a
            >
            <div class="dropdown-menu megamenu p-0" role="menu">
              <div class="container-fluid">
                <div class="row">
                  <ShowCasesMobile
                    :group1="menu.option_2_group_1"
                    :group2="menu.option_2_group_2"
                  />
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item" id="main_navbar">
            <a class="nav-link" href="../about-us">{{ menu.menu_3 }}</a>
          </li>
          <li class="nav-item" id="main_navbar mobilemenu">
            <a class="nav-link" href="../careers">{{ menu.careers_title }}</a>
          </li>
        </ul>
      </div>
      <!-- navbar-collapse.// -->
    </div>
    <!-- container-fluid.// -->
  </nav>
</template>
<script>
import axios from "axios";
import Resources from "@/components/menu/Resources/Resources.vue";
import ResourcesMobile from "@/components/menu/Resources/ResourcesMobile.vue";
import ShowCasesMobile from "@/components/menu/ShowCases/ShowCasesMobile.vue";
import ShowCases from "@/components/menu/ShowCases/ShowCases.vue";
import ShowCasesBlogs from "@/components/menu/ShowCases/ShowCasesBlogs.vue";
import ResourcesBlogs from "@/components/menu/Resources/ResourcesBlogs.vue";
import ICON_WSLogo from "@/assets/icons/WSLogo.vue";
import { is_beta } from "@/constants/constant.js";

export default {
  components: {
    Resources,
    ShowCasesBlogs,
    ResourcesBlogs,
    ICON_WSLogo,
    ShowCases,
    ResourcesMobile,
    ShowCasesMobile,
  },
  data: () => ({
    is_beta,
    isBurgerActive: false,
    lang: "en",
    mainMenu: [],
  }),
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    toggle() {
      this.isBurgerActive = !this.isBurgerActive;
      var navMain = document.getElementById("main_nav");
      var navMainbar = document.getElementById("main_navbar");
      var buttonmenu = document.getElementById("burger");
      navMainbar.addEventListener("click", function () {
        navMain.classList.remove("show");
        buttonmenu.classList.remove("active");
      });
    },
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/menu_/collections/main-menu/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.mainMenu = [];
            return;
          } else if (data.data.length == 0) {
            this.mainMenu = [];
            return;
          }
          this.mainMenu = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.blogs-header {
  font-size: 16px;
}
.navbar-nav li:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu {
  position: relative;
}

/* rotate caret on hover */
.dropdown > a:hover:after {
  text-decoration: underline;
  transform: rotate(180deg);
}

span.anticon.anticon-right {
  position: relative;
  top: 5px;
  left: 10px;
}
.hiringtext a.nav-link {
  padding: 4px 15px 5px 15px;
}
</style>
<style>
.boxstylingmobile:hover {
  box-shadow: 0 0px 5px 0px rgb(25 0 65 / 32%);
  transform: translate3d(0px, -2px, 0px);
}
.rightarrow {
  font-size: 20px;
  padding: 0px 10px;
  color: #0880e8;
}
.boxstylingmobile:hover .blogHeader {
  text-decoration: underline;
  color: #0880e8;
}
p.blogContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.hiringtext:hover {
  background: #fbe9cd;
}
.hiringtext {
  font-size: 16px;
  background: #f9deb4;
  border-radius: 16px;
}
svg.headerIcon {
  position: relative;
  top: 10px;
  left: 10px;
}
h3.blogHeader {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
}
img.serviceimage {
  width: 100%;
}

.dropdown-menu {
  top: 85%;
}
a.nav-link {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  transition: none;
}
a.nav-link svg {
  margin: 0px;
}
.dropdown-toggle::after {
  margin: 0px 0px 0px 10px;
}
.navbar-brand {
  padding: 0px 0px 0px 30px;
}
.hidden {
  visibility: hidden;
}
button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.burger-button {
  position: relative;
  height: 30px;
  width: 32px;
  display: block;
  z-index: 999;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  background-color: #000000;
  position: absolute;
  top: 50%;
  right: 6px;
  left: 6px;
  height: 2px;
  width: auto;
  margin-top: -1px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(0.8);
}

.burger-button:hover .burger-bar--2 {
  transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
  transform: scaleX(1);
}

.burger-bar--3 {
  transform: translateY(6px);
}

#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar {
  background-color: #000000;
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
}

a.nav-link > a:hover {
  color: #fff;
}
.navbar-nav {
  margin-bottom: 5px !important;
}
.navbar .navbar-nav .nav-link {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  transition: none;
}
a.nav-link.dropdown-toggle.show::after {
  top: 18px;
  right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.navbar-dark .navbar-nav .nav-link:focus {
  color: #000000 !important;
}
@media (min-width: 992px) {
  .navbar .megamenu {
    width: 100% !important;
    top: 75%;
  }
}
.navbar .navbar-nav .nav-link:hover {
  color: #0880e8;
  text-decoration: none;
}
.sm-icons {
  flex-direction: row;
}
.desktop {
  display: block;
  margin-left: 30px;
}
li#main_navbar\ mobilemenu {
  display: none;
}
.responsivecheck {
  display: flex;
  flex: auto;
  justify-content: start;
}
@media only screen and (max-width: 998px) {
  .sm-icons .nav-item {
    padding-right: 1em;
  }
  h3.submenuHeader {
    font-weight: 300 !important;
  }
  .desktop {
    display: none !important;
  }
  nav.navbar.navbar-expand-lg.navbar-dark.primary {
    padding: 20px 0px !important;
  }
  .beta {
    right: 15px !important;
    top: 2px !important;
  }
  .responsivecheck {
    justify-content: space-between !important;
  }
  svg.headerIcon {
    top: 8px !important;
    left: 10px !important;
  }
  li#main_navbar\ mobilemenu {
    display: block !important;
  }
}
.navbar .megamenu {
  padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 1000px) {
  .mobilemainmenu {
    display: none;
  }
  .desktopmainmenu {
    display: block;
  }
}
@media (max-width: 999px) and (min-width: 0px) {
  .mobilemainmenu {
    display: block;
  }
  .desktopmainmenu {
    display: none;
  }
}
@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position: static !important;
  }
  .mobilemenu {
    display: none;
  }
  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
}
.navbar-collapse {
  flex-grow: 0;
  padding: 0px 20px;
}
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */

@media (max-width: 767px) and (min-width: 0px) {
  .responsivedevice {
    display: none;
  }
}
@media (max-width: 451px) and (min-width: 0px) {
  div#main_nav {
    width: 95% !important;
  }
  img.logo {
    width: 70px !important;
    margin: 0px 10px !important;
  }
  a.navbar-brand svg {
    width: 75%;
  }
  a.navbar-brand img {
    width: 100%;
  }
}

@media all and (max-width: 998px) {
  li.nav-item {
    margin: 10px !important;
  }
  .navbar .navbar-nav .nav-link {
    font-size: 16px !important;
  }
  li.nav-item > a {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}
@media all and (max-width: 991px) {
  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
  .navbar-brand {
    padding: 0px 30px !important;
  }
  nav.navbar {
    bottom: 0;
  }
  div#main_nav {
    bottom: 60px !important;
  }

  div#main_nav {
    position: absolute;
    left: 0;
    width: 97%;
    margin: 10px;
    background-color: #f6f7fd;
    z-index: 99;
    -webkit-box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 10%);
    box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 10%);
    padding: 10px 20px;
    max-height: 550px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 6px;
  }
}
img.logo {
  width: 70px;
  margin: 0px 50px;
}

nav.navbar.navbar-expand-lg.navbar-dark.primary {
  position: fixed;
  z-index: 99;
  -webkit-box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 5%);
  background: #fff;
  padding: 15px 0;
  width: 100%;
  margin: 0px;
}
li.nav-item > a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px !important;
  padding-left: 15px !important;
  cursor: pointer;
}
li.nav-item {
  margin: 0px 10px;
  cursor: pointer;
  border-radius: 16px;
}
.dropdown-toggle.show {
  background: #f9deb4;
  border-radius: 16px;
}
.dropdown-toggle:hover {
  background: #fbe9cd;
  border-radius: 16px;
  color: #0880e8;
}
a.nav-link:hover {
  background: #fbe9cd;
  border-radius: 16px;
  color: #0880e8;
  text-decoration: none;
}
.boxstylingmobile {
  cursor: pointer;
  margin: 20px 0px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0px 5px 0px rgb(25 0 65 / 32%);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.2, 0.6, 0.6, 1);
  transition: transform 150ms cubic-bezier(0.2, 0.6, 0.6, 1);
  overflow: hidden;
  padding: 10px;
}
.beta {
  position: absolute;
  right: -12px;
  top: 5px;
  font-size: 12px;
}
.logo-wrapper {
  position: relative;
}
</style>

<template>
  <div class="col-md-4 col-sm-6 col-12 p-4">
    <p>{{ title }}</p>
    <div
      class="boxstylingmobile"
      v-for="(ShowCase, key) in ShowCases"
      :key="key"
      @click="handleClick(ShowCase.slug)"
    >
      <div class="row">
        <div class="col-md-1 col-sm-1 col-1">
          <ICON_Cube :size="icon_size" :color="footer_color" />
        </div>
        <div class="col-md-11 col-sm-11 col-11">
          <h3 class="blogHeader">{{ ShowCase.title }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ICON_Cube from "@/assets/icons/Cube.vue";
import { goTo } from "@/utility/util";
import axios from "axios";
export default {
  components: {
    ICON_Cube,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      lang: "en",
      ShowCases: [],
      footer_color: "black",
      icon_size: 24,
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    handleClick(slug) {
      goTo(slug);
    },
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/menu_|_show_case/collections/group_21/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.ShowCases = [];
            return;
          } else if (data.data.length == 0) {
            this.ShowCases = [];
            return;
          }
          this.ShowCases = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<template>
  <div class="col-md-4 p-4 responsivedevice" style="background: #f4f4f4">
    <div class="blogs-header">Featured from Blog</div>
    <div
      class="row"
      v-for="showCasesBlog in showCasesBlogs"
      :key="showCasesBlog"
      @click="handleClick(showCasesBlog.slug)"
    >
      <div class="col-md-2 col-sm-2 col-2 pt-4">
        <Image :fpath="`${showCasesBlog.image}.jpg`" class="serviceimage" />
      </div>
      <div class="col-md-10 col-sm-10 col-10 pt-4">
        <h3 class="blogHeader">{{ showCasesBlog.title }}</h3>
        <!-- <p class="blogContent">
          {{ showCasesBlog.description.slice(0, 50) }}...
        </p> -->
      </div>
    </div>
  </div>
</template>
<script>
import Image from "@/components/Image.vue";
import { goTo } from "@/utility/util";
import axios from "axios";
export default {
  components: {
    Image,
  },
  data() {
    return {
      lang: "en",
      showCasesBlogs: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    handleClick(slug) {
      goTo(slug);
    },
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/menu_|_show_case/collections/showcases_blogs/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.showCasesBlogs = [];
            return;
          } else if (data.data.length == 0) {
            this.showCasesBlogs = [];
            return;
          }
          this.showCasesBlogs = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
